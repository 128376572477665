import React from 'react'
import '../assets/scss/main.scss'

const Layout = ({ children }) => {
  return (
    <>
      { children }
    </>
  )
}
export default Layout
